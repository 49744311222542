.user-nav-menu--loading {
  animation: user-nav-menu-scale 2.0s infinite ease-in-out;
  background: #032c6b;
  height: 16px;
  width: 32px;
}

@keyframes user-nav-menu-scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1.0);
    opacity: 0;
  }
}

.footer-tertiary {
  background: #363636;
}

.footer-tertiary__body {
  align-content: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 10% 100px;

  @include range-bp($screen-sm-min, $screen-sm-max) {
    margin: 0 auto;
    padding-bottom: 24px;
    padding-left: 8%;
    padding-right: 8%;  
  }

  @include range-bp(null, $screen-sm-min) {
    padding-bottom: 24px;
    padding-left: 8%;
    padding-right: 8%; 
  }

  @include range-bp(null, $screen-xs-max) {
    display: block;
    padding-bottom: 20px;
  }

}

.footer-tertiary__btn {
  display: inline-block;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  @include range-bp(null, $screen-sm-max) {
    margin-right: 0;
    display: block;
  }

  @include range-bp(null, $screen-tn-max) {
    margin-bottom: 10px;
  }

}

.footer-tertiary__btns {
  list-style: none;
  padding: 0;
}

.footer-tertiary__header {
  @include font-size(15px);

  color: #fff;
  text-transform: uppercase;

  &--normal {
    @include font-size(14px);
    text-transform: none;
  }

}

.footer-tertiary__item{
  @include range-bp(null, $screen-sm-max) {
    width: 200px;
  }

  @include range-bp(null, $screen-xs-max) {
    margin: 0 auto;
    text-align: center;
  }

  &--width-240 {
    @include range-bp(null, $screen-sm-max) {
      width: 240px;
    }
  }
}

.footer-tertiary__link {
  @include font-with-fallback(gotham-medium, 13px);

  background: #ececec;
  border: 1px solid #7a7a7a;
  border-radius: 3px;
  color: #232222;
  line-height: 28px;
  padding: 6px 12px;
  text-transform: uppercase;

  &:hover {
    color: #232222;
    text-decoration: none;
  }

  @include range-bp(null, $screen-sm-max) {
    display: block;
    line-height: 16px;
    margin-bottom: 7px;
    text-align: center;
  }

  @include range-bp(null, $screen-tn-max) {
    @include font-size(11px);
  }

}
.case-intake-form {
  @include font-with-fallback(montserrat-regular);

  background: none;
  display: flex;
  flex: 0 0 820px;
  flex-direction: column;
  position: relative;

  @include range-bp(null, $screen-sm-max) {
    flex: 0 0 100%;
  }

  &__dropdown{
    height: auto;
    margin-right: 20px;
    width: 260px;
    
    @include range-bp(null, $screen-sm-max) {
      display: block;
      margin: 0 auto 20px;
    }
  }

  &__dropdown-menu {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    height: 290px;
    left: auto;
    margin: 2px 0 0 0;
    overflow-y: scroll;
    padding: 5px 0 5px;
    right: 0;
    top: auto;
    width: 100%;
    z-index: 991;
  }

  &__dropdown-item {
    @include font-size(16px);

    background: #ffffff;
    border-top: 1px solid #ffffff;
    color: #232222;
    cursor: pointer;
    font-weight: 400;
    padding: 11px 0 11px 17px;
    white-space: normal;

    &:hover {
      background: #bdbdbd;
      color: #ffffff;
    }
    &.is-selected {
      background: #bdbdbd;
      color: #ffffff;
    }

    &-input {
      display: none;
    }
  }
  
  &__field {
    display: flex;

    @include range-bp(null, $screen-sm-max) {
      display: block;
    }

    &-item {
      height: 64px;
      margin-right: 20px;
      width: 260px;

      @include range-bp(null, $screen-sm-max) {
        display: block;
        margin: 0 auto 20px;
      }

      &:focus {
        outline: none;
      }
    }
    

  }

  &__header {
    @include font-with-fallback(montserrat-medium, 46px);

    font-weight: normal;
    line-height: 56px;
    margin: 0 auto;
    text-align: center;

    @include range-bp(null, $screen-ss-max) {
      margin: 25px auto 0;
    }

    @include range-bp($screen-xs, $screen-sm-max) {
      @include font-size(30px);
      line-height: 36px;
    }
    
    @include range-bp($screen-tn+15, $screen-xs) {
      @include font-with-fallback(montserrat-semibold, 24px);
      line-height: 32px;
    }

    @include range-bp($screen-tn, $screen-tn+15) {
      @include font-with-fallback(montserrat-semibold, 22px);
      line-height: 30px;
    }

    &--small {
      @include font-with-fallback(montserrat-medium, 22px);
      
      color: #000;
      font-weight: normal;
      line-height: 56px;
      margin: 0 0 20px;
      text-align: center;

      @include bp-tn() {
        @include font-with-fallback(montserrat-regular);
      }

      @include range-bp(null, $screen-ss-max) {
        margin-bottom: 0;
      }

      @include range-bp($screen-tn, $screen-sm-max) {
        @include font-size(18px);
        line-height: 36px;
      }
    }

    &--link {
      color: #232222;
      &:hover {
        text-decoration: none;
      }
    }

  }

  &__hint{
    @include font-size(14px);

    margin-bottom: 0px;
    padding: 0px 10px;

    &-link{
      color: #2167a6;
      cursor: pointer;
      text-decoration: underline;
    }
  
  }

  &__home-form{
    padding: 10px;
    position: relative;

    @include range-bp(null, $screen-sm){
      padding-bottom: 10px;
      padding-top: 10px;
    }
  }
  
  &__input,
  &__input-button {
    @include font-size(17px);
    @include placeholder{
      @include font-with-fallback(montserrat-regular);
      color: #333;
    }

    background-color: rgb(242, 242, 242);
    border-color: rgb(0, 0, 0);
    border-radius: 5px;
    padding: 15px;
	  padding-right: 30px;
    border: 1px solid;
  }

  &__input{
    &::-ms-clear {
      display: none;
    }
  }

  &__input-button{
    border-color: rgb(0, 0, 0); 
    background-color: rgb(242, 242, 242);
    border: 1px solid;
    border-radius: 5px;
    height: 63px;
    position: relative;
    text-align: left;
    width: 260px;

    

    &:after {
      @include font-size(14px);
      @include icon($fa-var-chevron-down);

      color: #333;
      line-height: 22px;
      position: absolute;
      right: 17px;
    }
  }

  &__input--error {
    border: 1px #d21317 solid;

    &:focus {
      outline-offset: 0;
      outline: none;
    }
  }

  &__location-checker{
    margin: 0 20px 0 auto;
    width: 260px;

    &:after {
      @include font-size(16px);

      content: url("/images/ram/loading.gif");
      font-weight: bold;
      opacity: 0;
      position: absolute;
      text-align:center;
      transform: translateY(-50%);
      visibility: hidden;
      z-index: 2;
    }

    @include range-bp(null, $screen-sm-max) {
      margin: 0 auto;
    }

    &--default{
      &:after{
        opacity: 0;
        visibility: hidden;
      }
    }
    
    &--invalid{
      position: relative;
      &:after {
        border: 2px solid red;
        border-radius: 50%;
        box-sizing: content-box !important;
        color: red;
        content: "!";
        height: 20px;
        opacity: 1;
        right: 7px;
        top: 50%;
        visibility: visible;
        width: 20px;
      }
    }

    &--loader{
      position: relative;
      &:after {
        opacity: 1;
        right: 7px;
        top: 55%;
        visibility: visible;
      }
    }

    &--valid{
      position: relative;
      &:after {
        content: "\2713 ";
        border: 2px solid #008000;
        border-radius: 50%;
        box-sizing: content-box !important;
        color: #008000;
        height: 20px;
        opacity: 1;
        right: 7px;
        top: 50%;
        visibility: visible;
        width: 20px;
      }
    }
  }

  &__modal{
    &-container{
      max-height: 80px;
  
      @include range-bp(null, $screen-sm){
        max-height: 100px;
        max-width: 290px;
        width: 100%;
      }
    }
    
    &-icon {
      @include font-size(30px);
  
      color: #d21317;
      flex: 1 15%;
      margin: 15px 0 0 5px;
      text-align: center;
  
      @include range-bp(null,$screen-sm){
        flex: 1 13%;
        margin: 22px 0 0 0px;
      }
    }

    &-message-container{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &-subtitle{
      @include font-with-fallback(montserrat-regular);

      margin: 0.8em auto;
      padding: 0 5px;

      &-span{
        display: inline-block;

        @include range-bp($screen-sm, null){
          display: block;
        }
      }
  
      @include range-bp(null,$screen-sm){
        margin: 1.5em auto;
      }
    }

    &-zip{
      &-container{
        max-height: 70px;
        border-top: none;
        @include range-bp(null, $screen-sm){
          max-height: 70px;
          max-width: 250px;
          width: 100%;
        }
      }
      
      &-icon{
        margin-top: 0px;
        @include range-bp(null,$screen-sm){
          margin-top: 0px;
        }
        &:before{
          content: url("/images/ram/loading.gif");
          height: 20px;
          position: relative;
          top: 0px;
          width: 20px;
        }
        &-timeout{
          margin-top: 10px;
          @include range-bp(null,$screen-sm){
            margin-top: 10px;
          }
          &::before{
            content: '\f06a';
            font-family: FontAwesome;
          }
        }
      }

      &-subtitle{
        @include range-bp(null,$screen-sm){
          margin: 0.8em auto;
        }
        &-timeout{
          margin: 1em auto;
          @include range-bp(null,$screen-sm){
            margin: 0.5em auto;
          }
        }
      }
    }

    .modal-generic__close {
      @include font-with-fallback(montserrat-regular);
      padding-left: 0;
    }
  }

  &__submit {
    @include font-with-fallback(montserrat-semibold, 18px);

    background-color: #f7b650;
    border: none;
    border-radius: 3px;
    color: #232222;
    margin-right: 0;
    max-width: 100%;
    padding-left: 0;
    position: relative;
    text-transform: none;
    text-align: center;

    &::after {
      @include icon($fa-var-angle-right,18px);

      color: #232222;
      font-weight: bold;
      line-height: 64px;
      margin-left: 10px;
      position: absolute;
      top: 0;
    }
    
    @include range-bp(null, $screen-sm-max) {
      margin-right: auto;
      margin-bottom: 0;
    }

    &_label--mobile{
      display: none;
    }
    
    @include range-bp(null, $screen-xs-max) {
      &_label--mobile{
        display: inline-block;
      }

      &_label--desktop{
        display: none;
      }
    }
  }

  &__sub-header{
    @include range-bp(null,$screen-sm-max){
      display: none;
    }
  }

}

.case-intake-form__submit--linear-gradient-576dc6-35499c,
.case-intake-form__submit--linear-gradient-576dc6-35499c:hover,
.case-intake-form__submit--linear-gradient-576dc6-35499c:visited {
  background-image: linear-gradient(to right, rgb(87, 109, 198) 21%, rgb(53, 73, 156) 100%);
  color: #fff;

  &::after {
    color: #fff;
  }
}

.case-intake-form__submit {
  background: linear-gradient(to right, #576dc6 31%, transparent)#35499c;
  color: #fff;
  text-decoration: none;
  transition: background-color .2s;
}

.case-intake-form__submit:hover, .case-intake-form__submit:focus {
  background: linear-gradient(to right, #576dc6 31%, transparent)#e91e63;
  color: #fff;
}

.case-intake-form__field--center-content {
  justify-content: center;
}

// LMS-23030
.case-intake-form__submit--rounded-border {
  border-radius: 20px !important;
  transition: none !important;
}

.case-intake-form__submit--shine {
  position: relative;
  overflow: hidden;
}

.case-intake-form__submit--shine::before {
  content: "";
  position: absolute;
  top: 0;
  left: -300px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(120deg, rgba(255, 255, 255, 0) 30%, 
  rgba(70, 130, 180, 0.1) 35%,
  rgba(70, 130, 180, 0.3) 40%,
  rgba(255, 255, 255, 0) 41%);
  animation: shine 3s infinite linear;
}

@keyframes shine {
  0% {
    left: -200px;
  }
  50% {
    left: calc(100% + 200px);
  }
  100% {
    left: calc(100% + 200px);
  }
}

.case-intake-form__category.open .case-intake-form__dropdown-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  left: 0;
  overflow: hidden;
  width: 540px;

  @include range-bp(null, $screen-sm-max) {
    display: block;
    left: auto;
    width: 100%;
    overflow-y: scroll;
  }
}

.case-intake-form__category--3.open .case-intake-form__dropdown-menu {
  height: 240px;
  
  @include range-bp(null, $screen-sm-max) {
    height: 290px;
  }
}
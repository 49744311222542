@mixin service-menu-fluid-item-property($prop) {
  @include poly-fluid-sizing($prop, ($screen-xs: 15px, $screen-sm: 5px, $screen-md: 8px, $screen-lg: 15px));
}

.service-menu {
  margin: 0;
  padding: 0;
  text-align: center;
}

.service-menu__item {
  @include font-size(15px);

  font-family: $font-family-sans-serif;
  display: inline-block;
  list-style: none;

  @include bp-tn() {
    display: block;
    padding-bottom: 5px;
    padding-left: 0;
    padding-right: 0;
  }

  &:first-child {
    @include bp-tn() {
      display: block;
    }
  }

  &:not(:first-child) {
    &:before {
      @include icon($fa-var-circle, 8px);
      @include service-menu-fluid-item-property('margin-right');

      position: relative;
      top: -2px;

      @include bp-tn() {
        display: none;
      }
    }
  }

  &:nth-child(2) {
    @include service-menu-fluid-item-property('padding-left');
    @include service-menu-fluid-item-property('padding-right');

    @include bp-tn() {
      padding-left: 0;
      padding-right: 0;

      &::before {
        display: none;
      }
    }
  }
}

.service-menu__link {
  color: #97a8a7;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
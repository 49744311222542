// @media range mixin
@mixin range-bp($min: null, $max: null, $screen: "") {

  $range: "";

  @if($min) {
    $range: "(min-width: #{$min})";
  }

  @if($max) {

    @if($min) {
      $range: "#{$range} and "
    }

    $range: "#{$range}(max-width: #{$max})"
  }

  @if($min or $max) {
    @media #{$screen} #{$range} {
      @content;
    }
  }

}

// Shorthand mixins for the breakpoint
@mixin bp-tn {
  @include range-bp(null, $screen-tn-max) {
    @content;
  }
}

@mixin bp-xs {
  @include range-bp($screen-xs-min, $screen-xs-max) {
    @content;
  }
}

@mixin bp-ss {
  @include range-bp($screen-ss-min, $screen-ss-max) {
    @content;
  }
}

@mixin bp-sm {
  @include range-bp($screen-sm-min, $screen-sm-max) {
    @content;
  }
}

@mixin bp-md {
  @include range-bp($screen-md-min, $screen-md-max) {
    @content;
  }
}

@mixin bp-lg {
  @include range-bp($screen-lg-min) {
    @content;
  }
}

.explore-lm__grid {
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
	grid-template-columns: repeat(12, 1fr); 
	grid-column-gap: 0px;
	grid-row-gap: 0px; 
	margin-top: .5rem;
	@media (max-width: 1430px) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

.explore-lm__categories {
	column-count: 3;
	column-gap: 0;
	padding-top: 10px;

	@include bp-tn() {
		padding-left: 15px;
		padding-right: 15px;
	}

	@include range-bp(null, $screen-sm-max) {
		grid-column: 1 / 13;
		grid-row: 2;
	}

	@include range-bp($screen-md, null) {
		grid-column: 1 / 9;
		grid-row: 2;
	}


}

.explore-lm__categories-item {
	@include font-with-fallback(montserrat-semibold);

	font-size: 16px;
	font-weight: normal;
	line-height: 35px;
	color: #337AB7;
	a {
		color: inherit !important;
		text-decoration: none;
	}
}

.explore-lm__categories-link {
	color: #337ab7;
	text-decoration: none;

	&:hover {
		color: #23527c;
	}
}

.explore-lm__categories-link.explore-other-categories {
	cursor:pointer;
}

.explore-lm__other {
	padding-top: 15px;
	@include bp-tn() {
		padding-left: 0;
		padding-right: 0;
	}

	@include range-bp(null, $screen-sm-max) {
		grid-column: 1 / 13;
		grid-row: 4;
	}

	@include range-bp($screen-md, null) {
		grid-column: 9 / 13;
		grid-row: 2;
	}
}

.explore-lm__other-title {
	@include font-with-fallback(montserrat-medium);

	font-size: 18px;
	font-weight: normal;
	margin: 0 auto 0 0;
	text-align: left;
	text-transform: none;
	color: #232222;

	@include range-bp(null, $screen-sm-max) {
		grid-row: 3;
		grid-column: 1 / 13;
		padding-bottom: 10px;
		padding-top:15px;
	}

	@include range-bp($screen-md, null) {
		grid-column: 9 / 13;
		grid-row: 1;
	}
}


.explore-lm__other-ddown {
	margin: 0 auto 10px 0;
	max-width: 404px;
	min-width: 320px;

	@include range-bp(null, $screen-sm-max) {
		max-width: none;
		width: 100%;
	}
}

.explore-lm__other-ddown-content,
.explore-lm__other-ddown-content.dropdown-menu {
	@include font-with-fallback(montserrat-regular);

	font-size: 15px;
	font-weight: normal;
	background: #fff;
	color: #232222;
	cursor: pointer;
	margin-top: 2px;
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	border-radius: 4px;

}

.explore-lm__other-ddown-item,
.explore-lm__other-ddown-item.dropdown-item {
	@include font-with-fallback(montserrat-regular);

	font-size: 14px;
	font-weight: normal;
	display: block;
	color: #232222;
	padding: 0 20px;
	text-decoration: none;
	white-space: normal;
	line-height: 35px;

	&:hover, &:focus {
		background: #E8EAED;
		color: #232222;
	}
}

.explore-lm__other-ddown .explore-lm__other-ddown-title {
	@include font-with-fallback(montserrat-regular);

	font-size: 14px;
	font-weight: normal;
	background: #fff;
	border: 1px solid #DADCE0;
	border-radius: 4px;
	color: #232222;
	cursor: pointer;
	overflow: hidden;
	padding: 8px;
	position: relative;
	text-align: left;
	white-space: nowrap;
	width: 100%;
	max-width: 404px;
	height: 40px;

	@include range-bp(null, $screen-sm-max) {
		max-width: 100%;
	}

}

.explore-lm__other-ddown .explore-lm__other-ddown-title {
	&:after {
		@include icon($fa-var-angle-down, 20px);

		background: #fff;
		position: absolute;
		color: #5F6368;
		right: 10px;
		text-align: center;
		top: 5px;
		width: 20px;
	}
}

.explore-lm__other-ddown.open .explore-lm__other-ddown-title {
	&:after {
		@include icon($fa-var-angle-up, 20px);

		background: #fff;
		position: absolute;
		color: #5F6368;
		right: 10px;
		text-align: center;
		top: 5px;
		width: 20px;
	}
}

.explore-lm__overlay {
	color: #232222;
	padding: 25px 0 30px 0;
	& > .overlay__close {
		color: #BDC1C6;
		text-transform: lowercase;
		font-weight: bold;
	}
}

.explore-lm__title {
	@include font-with-fallback(montserrat-medium);

	font-size: 20px;
	font-weight: normal;
	color: #232222;
	grid-row: 1;
	margin: 0;
	max-width: calc(100% - 25px);

	@include range-bp(null, $screen-sm-max) {
		grid-column: 1 / 13;
		grid-row: 1;
	}

	@include range-bp($screen-md, null) {
		grid-column: 1 / 9;
		grid-row: 1;
	}

	@include bp-tn() {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.explore-lm__toggle {
	background: none;
	border: none;
	color: #032c6b;
	cursor: pointer;
	outline: none;
	padding: 0;
}


.w-find-lawyers {
    background: #fff;
}

.w-find-lawyers__header {
    @include font-size(30px);

    margin: 20px 0;
    text-align: center;

    @include range-bp(null, $screen-ss) {
        @include font-size(30px);
    }
}

.w-find-lawyers__item {
    display: inline-block;
    padding: 10px 0;
    width: 100%;
}

.w-find-lawyers__item--four_columns {
    @include range-bp($screen-sm) {
        width: 50%;
    }

    @include range-bp($screen-md) {
        width: 25%;
    }
}

.w-find-lawyers__link {
    @include font-size(16px);

    cursor: pointer;
    line-height: 22.5px;
    text-decoration: none;
    text-transform: capitalize;
}

.w-find-lawyers__list {
    column-count: 3;
    column-gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;

    @include range-bp($screen-ss, $screen-sm) {
        column-count: 2;
        column-gap: 20px;
    }

    @include range-bp(null, $screen-xs-max) {
        column-count: 1;
        column-gap: 0;
    }
}

.w-find-lawyers__list--border-top {
    border-top: 1px solid #ced8e0;
    margin-top: 30px;
    padding-top: 30px;
}

.w-find-lawyers__list-flex {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 0;
    padding: 0 !important;
}

.w-find-lawyers__nav {
    border-bottom: 2px solid #eaf0f6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}

.w-find-lawyers__nav-item {
    @include font-size(18px);

    border-bottom: 2px solid #eaf0f6;
    cursor: pointer;
    flex: 0 1 auto;
    padding: 20px 14px;
    position: relative;
    top: 2px;

    @include range-bp(null, $screen-xs) {
        @include font-size(16px);
    }

    &:hover {
        border-color: #8a8c8b;
        color: #232222;
    }

    &:hover .w-find-lawyers__nav-link {
        color: #232222;
        text-decoration: none;
    }

    @at-root #{&}.is-selected {
        border-color: #8a8c8b;
        color: #232222;
        cursor: default;
    }

    @at-root #{&}.is-selected .w-find-lawyers__nav-link {
        color: #232222;
        cursor: default;
    }
}

.w-find-lawyers__nav-link {
    color: #337ab7;
}

.w-find-lawyers__tab {
    display: none;
    padding: 40px 30px;

    @include range-bp(null, $screen-ss) {
        padding: 25px 15px;
    }

    &.is-active {
        display: block;
    }
}

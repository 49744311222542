.footer-redesign .footer__flex {
    padding-top: 20px;
}

.footer-redesign .footer__menu {
    margin: 25px 0 10px;

    @include range-bp(null, $screen-sm) {
        margin: 25px 0 0;
    }
}

.footer-redesign .footer__contact {
    padding-bottom: 20px;
}

.footer-redesign .footer__copyright {
    padding: 20px 15px 40px;
}

.footer-redesign .service-menu__link {
    @include range-bp(null, $screen-sm) {
        line-height: 40px;
    }
}

/**
 * LMS-25589: Scroll-to-top button styles provided by VWO
 */
 html {
	scroll-behavior: smooth;
}

#scroll-to-top-widget * {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-align: left;
	background-color: transparent;
}

#scroll-to-top-widget .scroll-to-top-btn {
	background: none;
	display: none;
	cursor: pointer;
	position: fixed;
	bottom: 24px;
	right: 24px;
	z-index: 2147483000;
	border: none;
	outline: none;
}

#scroll-to-top-widget .scroll-to-top-btn:hover {
	opacity: 0.9;
}
/** End of LMS-25589 */
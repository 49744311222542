@import "grid-mixins";

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

@include make-custom-grid-columns;

// Redefine responsive utilities
.visible-tn,
.visible-tn-block,
.visible-tn-inline,
.visible-tn-inline-block,
.visible-ss,
.visible-ss-block,
.visible-ss-inline,
.visible-ss-inline-block {
  display: none !important;
}

@include responsive-utility(tn, $screen-tn-min, $screen-tn-max, true, $tn-gutter-width, true) {
  @include responsive-invisibility('.visible-xs');
  @include responsive-visibility('.hidden-xs');
}

@include responsive-utility(xs, $screen-xs-min, $screen-xs-max, false, $xs-gutter-width, true);

@include responsive-utility(ss, $screen-ss-min, $screen-ss-max, false, $ss-gutter-width, true) {
  @include responsive-invisibility('.visible-xs');
  @include responsive-visibility('.hidden-xs');
  @include responsive-invisibility('.visible-sm');
  @include responsive-visibility('.hidden-sm');
}

@include responsive-utility(sm, $screen-sm-min, $screen-sm-max, false, $sm-gutter-width);

@include responsive-utility(md, $screen-md-min, $screen-md-max, false, $md-gutter-width);

@include responsive-utility(lg, $screen-lg-min, null, false, $lg-gutter-width);
// End of redfinition

@include range-bp(null, $screen-tn - 1) {
  .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
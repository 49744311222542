@import "grid-bp-rules";

// Additional grid mixins for tn.
@mixin make-tn-column($columns, $gutter: $tn-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left:  0;
  padding-right: 0;
}

@mixin make-tn-column-offset($columns) {
  @media (min-width: $screen-tn-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}

@mixin make-tn-column-push($columns) {
  @media (min-width: $screen-tn-min) {
    left: percentage(($columns / $grid-columns));
  }
}

@mixin make-tn-column-pull($columns) {
  @media (min-width: $screen-tn-min) {
    right: percentage(($columns / $grid-columns));
  }
}

@mixin make-ss-column($columns, $gutter: $ss-gutter-width) {
  position: relative;
  float: left;
  width: percentage(($columns / $grid-columns));
  min-height: 1px;
  padding-left:  0;
  padding-right: 0;
}

@mixin make-ss-column-offset($columns) {
  @media (min-width: $screen-ss-min) {
    margin-left: percentage(($columns / $grid-columns));
  }
}

@mixin make-ss-column-push($columns) {
  @media (min-width: $screen-ss-min) {
    left: percentage(($columns / $grid-columns));
  }
}

@mixin make-ss-column-pull($columns) {
  @media (min-width: $screen-ss-min) {
    right: percentage(($columns / $grid-columns));
  }
}

// We need .col-tn-# .col-ss-#
@mixin make-custom-grid-columns() {
  $i: 1;
  $list: ".col-tn-#{$i}, .col-ss-#{$i}";
  @for $i from 2 through 12 {
    $list: "#{$list},.col-tn-#{$i}";
  }
  #{$list} {
    position: relative;
    // Prevent columns from collapsing when empty
    min-height: 1px;
  }
}

// End of shothand decalarations

@mixin responsive-utility($symbol, $min: null, $max: null, $isSmallest: false, $gutter: 0, $isPercentileWidth: false) {

  $container-max: $min;
  $container-width: $min - $gutter;
  $container-gutter: 0;

  @if($min == null) {
    $container-max: $max;
  }

  @if($isPercentileWidth) {
    $container-width: 100%;
    $container-gutter: $gutter / 2;
  }

  .container {
    @include range-bp($min) {
      width: $container-width;
      padding-left: $container-gutter;
      padding-right: $container-gutter;
    }
  }

  // Let's re-declare grids.
  @if($isSmallest) {
    @include make-grid($symbol);
    $min: null;
  } @else {
    @include range-bp($min) {
      @include make-grid($symbol);
    }
  }
  // End of grid re-decalaration

  @include range-bp($min, $max) {

    .row {
      margin-left: 0;
      margin-right: 0;

      & > [class^="col-"] {
        padding-left: 0;
        padding-right: 0;
      }

    }


    @include responsive-visibility('.visible-#{$symbol}');

    .visible-#{$symbol}-block {
      display: block !important;
    }

    .visible-#{$symbol}-inline {
      display: inline !important;
    }

    .visible-#{$symbol}-inline-block {
      display: inline-block !important;
    }

    @content;

    @include responsive-invisibility('.hidden-#{$symbol}');

  }
}

@mixin mobile-devices-landscape {
  @media screen and (max-width: 767px) and (orientation: landscape){
    @content;
  }
}

@mixin media-md {
  @media (max-width: 991px){
    @content;
  }
}

@mixin media-sm {
  @media (max-width: 767px){
    @content;
  }
}

@mixin media-xs {
  @media (max-width: 480px){
    @content;
  }
}

@function px2rem($value) {
  $remSize: $value / 16px;
  @return #{$remSize}rem;
}

@mixin font-size($size-in-px) {
  font-size: $size-in-px;
  font-size: px2rem($size-in-px);
}

@mixin font-with-fallback($font-family, $size: null) {
  font-family: $font-family, $font-family-sans-serif;
  @if ($size) {
    @include font-size($size);
  }
}
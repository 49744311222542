$hero_dir: "/bd/homepage-redesign/images/hero/";
$class-slug: hero !default;

.hero {
  background-color: white;
  background-image: url(#{$hero_dir + "desktop-default.jpg"});
  background-position-x: 75%;
  background-position-y: 0;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 1px solid #f0ebe7;

  .container {
    @include range-bp(null, $screen-sm - 1) {
      background: rgba(250, 250, 250, 0.7);
    }
  }

  @include bp-sm() {
    background-position-x: 75% !important;
    background-position-y: 0%;
  }

  @include range-bp(1400px, null) {
    background-position-x: 12%;
    background-position-y: 0%;
  }

  @include range-bp($screen-sm, null){
    height: 435px;
  }

}

.hero__home {
  align-items: center;
  background: none;
  display: flex;
  min-height: 435px;

  @include range-bp($screen-sm) {
    flex: 0 0 380px;
  }

  @include range-bp(null, $screen-ss-max) {
    @include poly-fluid-sizing('margin-bottom', ($screen-tn: 18px, $screen-sm: 50px, $screen-md: 0));
    @include poly-fluid-sizing('margin-top', ($screen-tn: 18px, $screen-sm: 50px, $screen-md: 0));

    display: block;
    justify-content: center;
  }

  @include range-bp($screen-sm, null){
    height: 400px;
  }

  // LMS-20490
  & .case-intake-form__field-item.case-intake-form__submit.case-intake-form__submit--linear-gradient-576dc6-35499c:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .5);
  }
  
}


// LMS-20700
.LMS-20700 {
  position: relative;
}
.LMS-20700 > .lds-default {
  position: absolute;
  left: 6px;
  top: calc(50% - 15px)
}

// preloader css
.lds-default {
  /* change color here */
  color: rgb(160, 158, 158);
}
.btn-view-online-library {
  color: black;
  left: 15px !important;
}
.btn-find-your-lawyer-now {
  color: black;
  left: 15px !important;
}
.lds-default,
.lds-default div {
  box-sizing: border-box;
}
.lds-default {
  display: none;
  position: relative;
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
.lds-default div {
  position: absolute;
  width: 4.4px;
  height: 4.4px;
  background: currentColor;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 46%; //36..8px
  left: 82.8%; // 66.24px;
}
.lds-default div:nth-child(2) {
  display: none;
  animation-delay: -0.1s;
  top: 28.5%;//22.08px;
  left: 77.8697375%; //62.29579px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 14.1302625%; //11.30421px;
  left: 64.4%; //51.52px;
}
.lds-default div:nth-child(4) {
  display: none;
  animation-delay: -0.3s;
  top: 9.2%; //7.36px;
  left: 46%; //36.8px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 14.1302625%; //11.30421px;
  left: 28.5%; //22.08px;
}
.lds-default div:nth-child(6) {
  display: none;
  animation-delay: -0.5s;
  top: 28.5%; //22.08px;
  left: 14.1302625%; //11.30421px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 46%; //36.8px;
  left: 9.2%; //7.36px;
}
.lds-default div:nth-child(8) {
  display: none;
  animation-delay: -0.7s;
  top: 64.4%; //51.52px;
  left: 14.1302625%; //11.30421px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 77.8697375%; //62.29579px;
  left: 28.5%; //22.08px;
}
.lds-default div:nth-child(10) {
  display: none;
  animation-delay: -0.9s;
  top: 82.8%; //66.24px;
  left: 46%; //36.8px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 77.8697375%; //62.29579px;
  left: 64.4%; //51.52px;
}
.lds-default div:nth-child(12) {
  display: none;
  animation-delay: -1.1s;
  top: 64.4%; //51.52px;
  left: 77.8697375%; //62.29579px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.header__grid {
  
  @include range-bp($screen-sm, $screen-sm) {
    flex-direction: row;

    & > * {
      justify-self: left;
    }
  }

  @include bp-tn(){
    padding-left: 0px;
    padding-right: 0px;
  }
}

.header__nav-item {
  color: #012a6a;
}

.header--mobile{

  @include bp-tn(){
    .container {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  
  .header__nav{
    flex-grow: 1;
  }
  
  &.search {
    margin:28px 29px 0 32px;
    position: relative;

    .fa {
        cursor: pointer;
    }
  }

  .fa-angle-down,
  .fa-angle-up {
    font-weight: 600;
    margin-left: 4px;
  }

  .fa-search {
    font-size:19px;
  }
  
  .header__nav--mobile{
    display: block;
    opacity: 0;
    z-index: -1;

    > * {
      margin: 0px;
    }
  }

}

@include range-bp(null, $screen-ss-max){
  .header__nav--desktop{
    display: none;
  }
}
.header {
  background: #fff;
  color: #032c6b;
  height: 77px;
  position: relative;

  &:after {
    bottom: -7px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 7px 7px -7px inset;
    content: " ";
    display: block;
    height: 7px;
    left: 0px;
    position: absolute;
    width: 100%;
  }
}

.header__grid {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 18px;
  padding-top: 18px;
}

.header__logo {
  max-width: 206px;
  width: 100%;
  .logo__tagline {
    @include font-with-fallback(Gotham-Medium);
    display: none;
  }
}

.header__nav {
  @include font-with-fallback(Gotham-Book, 16px);

  align-items: center;
  display: flex;
  height: 35px;
  justify-content: flex-end;
  justify-self: end;
  min-width: 300px;
  position: relative;

  &-logged {
    line-height: 18px;
    padding-right: 20px;
    position: relative;

    &:after {
      @include font-size(12px);
      @include icon($fa-var-chevron-down);
      
      font-weight: bold;
      line-height: 16px;
      position: absolute;
      top: 0;
      right: 2px;
    }

    @include bp-tn() {
      font-size: 14px;
    }
  }

  & > * {
    margin-right: 15px;
  }

  & > *:first-child {
    border-right: 1px solid #777778;
    line-height: 18px;
    margin-right: 0;
    padding-right: 30px;
    position: relative;

    &:after {
      @include font-size(12px);
      @include icon($fa-var-chevron-down);
      
      font-weight: bold;
      line-height: 16px;
      position: absolute;
      top: 0;
      right: 12px;
    }

    @include bp-tn() {
      font-size: 14px;
    }
  }

  & > *:nth-child(2) {
    margin-left: 15px;

    @include bp-tn() {
      @include font-size(14px);
      margin-right: 5px;
    }
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.header__nav.is-search-active {
  .explore-lm__toggle,
  & > *:nth-child(2),
  .search__toggle {
    display: none;
  }

  .search__form {
    display: flex;
    height: 100%;
  }
}

.header__nav-item {
  @include font-with-fallback(Gotham-Medium);

  color: #032c6b;
  text-decoration: none;

  &:hover {
    color: #032c6b !important;
    text-decoration: none;
  }
}

.header__tagline {
  @include font-with-fallback(Gotham-Medium);
  
  flex-grow: 1;
  text-align: center;

  @include range-bp($screen-sm, $screen-md) {
    padding-left: 10px;
    text-align: left;
  }
}

.case-posted-count {
  display: inline-block;
  list-style: none;
  padding: 0;
  text-transform: uppercase;

  @include range-bp(null, 576px){
    margin: 0;
    text-align: left;
  }

  &__line-1 {
    @include font-with-fallback(gotham-bold, 17px);

    color: #7a7878;
    line-height: 17px;

    @include range-bp($screen-ss, $screen-sm-min) {
      @include font-size(14px);
    }
  }

  &__line-2 {
    @include font-with-fallback(gotham-medium, 32px);

    color: #5f5e5e;
    line-height: 32px;

    @include range-bp($screen-ss, $screen-sm-min) {
      @include font-size(26px);
    }
  }

  &__line-3 {
    @include font-with-fallback(gotham-medium, 17px);

    color: #7a7979;
    line-height: 17px;
    text-align: right;

    @include range-bp($screen-ss, $screen-sm-min) {
      @include font-size(14px);
    }
  }
}
.content {
  @include font-with-fallback(montserrat-regular, 18px);

  line-height: 36px;

  &__action-btn {
    text-align: center;
  
    &-anchor {
      @include font-with-fallback(montserrat-semibold, 18px);

      background-color: #f7b650;
      box-shadow: 0 5px 7px rgba(71, 71, 71, 0.27);
      color: #232222;
      padding: 26px 48px;
      text-transform: uppercase;
      text-decoration: none;
  
      @include range-bp($screen-xs, 410px) {
        display: block;
        margin: 0 auto;
        max-width: 300px;
        padding: 18px;
      }
  
      @include bp-tn() {
        @include font-size(16px);
        display: block;
        line-height: 26px;
        margin: 0 auto;
        max-width: 300px;
        padding: 20px 15px;
      }
  
      &:hover {
        color: #fff;
        text-decoration: none;
      }
  
    }
  
  }

  &__body{
    padding: 20px 0 50px 0;
  }

  &__bold {
    @include font-with-fallback(montserrat-medium);
    font-weight: normal;
  }
  
  &__column{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    @include range-bp(null, $screen-sm-max) {
      flex-direction: column;
      padding: 0 5%;
    }

    @include bp-tn() {
      padding: 0;
    }

    &-item{
      flex: 1 auto;
      width: 50%;

      &:first-child{
        padding-right: 55px;
        
        @include range-bp(null, $screen-sm-max) {
          padding-right: 0px;
        }
      }

      &:last-child{
        padding-left: 55px;
        
        @include range-bp(null, $screen-sm-max) {
          padding-left: 0px;
        }
      }

      &-paragraph{
        &:last-child{
          margin-bottom: 0;
        }
      }
      
      @include range-bp(null, $screen-sm-max) {
        width: auto;
      }
    }
  }

  &__cta-button-link{
    padding: 60px 0px;
  }

  &__header {
    @include font-with-fallback(montserrat-light, 38px);

    font-weight: normal;
    line-height: 54px;
    margin: 0 0 20px;
    text-align: center;

    &--highlight {
      @include font-with-fallback(montserrat-medium);
    }

    @include range-bp(null, $screen-ss){
      @include font-size(30px);
      line-height: 42px;
      margin-bottom: 0;
    }
    
  }

  &__link {
    @include font-with-fallback(montserrat-medium);

    color: #2167a6;
    text-decoration: underline;

    &-container{
      padding: 60px 0px 0px;
      text-align: center;
    }
  }

  &__list {
    list-style: none;
    margin: 50px auto 100px;
    padding: 0;
    width: 82%;
  
    &-item {
      margin-bottom: 13px;
      padding-left: 20px;
      position: relative;
      z-index: 0;
  
      @include bp-tn(){
        padding-left: 15px;
      }
  
      &::before {
        @include icon("\f111", 10px);
        left: 0;
        line-height: 12px;
        position: absolute;
        top: 10px;
        z-index: 0;
  
        @include range-bp($screen-xs, $screen-sm){
          @include font-size(8px);
        }
  
        @include range-bp(null, $screen-xs){
          @include font-size(6px);
          top: 10px;
        }
      }
    }
  
    @include range-bp(null, $screen-ss){
      margin-bottom: 0px;
      padding-bottom: 60px;
    }
    
    @include range-bp(null, $screen-ss-max) {
      width: 90%;
    }

    @include range-bp(null, $screen-xs) {
      margin-top: 20px;
    }
  }
  
  &__paragraph-single {
    margin: 60px 0 0;
    padding: 0 5%;

    @include range-bp(null, $screen-xs){
      @include font-size(16px);
      line-height: 36px;
      margin-top: 20px;
    }
  }

  @include range-bp(null, $screen-xs-max){
    .container{
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__line-break {
    @include range-bp(null, $screen-ss-max) {
      display: none;
    }
  }
}
.social-media-auto-like {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 20px 0;
    margin: 0;
}

.social-media-auto-like__item {
    align-self: start;
}

.social-media-auto-like__separator {
    display: flex;
    padding: 0;
    margin: 0;
}

.social-media-auto-like__separator-line {
    border-top: 1px solid #bdc1c5;
    width: 46%;
}

.social-media-auto-like__separator-item {
    align-self: center;
}

.social-media-auto-like__separator-text {
    color: #bdc1c5;
    padding: 0 10px;
}

.social-media-auto-like__p {
    margin: 0;
    padding-top: 10px;
}

.IN-widget {
    background-color: #006097;
    border-radius: 5px;
    height: 28px;
    padding-top: 1px !important;
}
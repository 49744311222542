// Add a breakpoint which covers 0..479px
$screen-tn: 320px !default;
$screen-tn-min: $screen-tn !default;

$screen-ss: 576px !default;
$screen-ss-min: $screen-ss !default;

$screen-tn-max: ($screen-xs-min - 1) !default;
$screen-xs-max: ($screen-ss-min - 1);
$screen-ss-max: ($screen-sm-min - 1) !default;

$tn-gutter-width: 0;
$xs-gutter-width: 24px;
$ss-gutter-width: 24px;
$sm-gutter-width: 24px;
$md-gutter-width: 32px;
$lg-gutter-width: 36px;

$gotham-font-path: "/css/fonts/gotham-fonts/";

/**
 * taken from BS4
 * @link https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss
 */
$font-family-sans-serif: Arial, sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;

$c-case-intake-form-radius: 5px;

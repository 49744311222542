.other-categories{

  &__modal{

    &-body{
      
      @include range-bp(null, $screen-lg){

        padding-bottom: 0px;
        &:after{
          background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 90%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          bottom: 0;
          content: "";
          height: 2.5em;
          left: 0;
          margin: 0 10px 0 10px;
          pointer-events: none;
          position: absolute;
          width: 95%;
          z-index: 1;
        }
      }

      &.isScrollEnd{
        
        &::after{
          background: none;
          height: 0;
          width: 0;
        }
      }
    }

    &-footer {
      @include font-with-fallback(montserrat-regular,12px);
      
      display: block;
      padding-bottom: 10px;
      text-align: center;
	  color: #232222;

      &-icon{
        margin-right: 5px;
      }
    }
  }
}

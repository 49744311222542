@include range-bp(null, $screen-sm) {
    .sticky-header__button-mobile {
        display: block;
    }

    .sticky-header__button-desktop {
        display: none;
    }
}

.sticky-header__button-mobile {
    display: none;
}

.sticky-header.sticky-header--yellow {
    background: #fcc77a !important;
}

.sticky-header.sticky-header--yellow .sticky-header__body {
    background: transparent !important;
}

.w-testimonials {
	margin: 0px auto;
	padding: 0 10px;
	width: 55%;
	
	@include range-bp(null, $screen-ss) {
		margin: 0px auto;
	}
	
	@include range-bp($screen-ss, $screen-sm) {
		width: 75%;
	}
	
	@include bp-xs() {
		width: 80%;
	}
	
	@include bp-tn() {
		width: 100%;
	}
}

.w-testimonials__header {
	@include font-with-fallback(montserrat-light, 38px);
	
	color: #222;
	font-weight: normal;
	line-height: 54px;
	margin-top: 0px;
	text-align: center;
	
	@include range-bp(null, $screen-ss) {
		@include font-size(30px);
		line-height: 42px;
		margin-bottom: 0;
	}
	
	@include range-bp($screen-md+1) {
		padding: 0 60px;
	}
}

.w-testimonials__item {
	overflow: hidden;
	padding: 0;
	
	@include bp-tn() {
		cursor: pointer;
		user-select: none;
	}
}

.w-testimonials__item-body-author {
	@include font-with-fallback(montserrat-regular, 18px);
	
	float: right;
	padding-left: 25px;
	position: relative;
	text-align: right;
	
	@include range-bp(null, $screen-ss) {
		padding-left: 0px;
	}
}

.w-testimonials__item-body-author-dash {
	margin-right: 15px;
}

.w-testimonials__item-body-author-from{
  &:before{
    content: attr(data-from);
  }
}

.w-testimonials__item-body-author-holder{
  &:before{
    content: attr(data-author);
  }
}

.w-testimonials__item-body-author-practice {
  display: inline-block;
  
  &:before{
    content: attr(data-practice);
  }
}

.w-testimonials__item-body-content {
	@include font-with-fallback(Times New Roman, 22px);
	
	color: #878787;
	font-style: italic;
  line-height: 36px;

  &:before{
    content: attr(data-content);
  }
}
.is-exit-popup-open {
  overflow: hidden;
}

.exit-popup {
  bottom: 0;
  left: 0;
  opacity: 0;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.5s;
  visibility: hidden;
  z-index: 1050;
}

.exit-popup.open-popup {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.exit-popup__body {
  padding: 25px 20px 0;
}

.exit-popup__button {
  @include font-with-fallback("Gotham-Medium");

  background: #02397b;
  border-radius: 3px;
  color: #fff;
  display: block;
  padding: 15px 10px;
  text-align: center;
  text-decoration: none;
}

.exit-popup__button:hover {
  background: #0b54ac;
  color: #fff;
  text-decoration: none;
}

.exit-popup__close {
  background: transparent;
  border: 0;
  color: #000;
  cursor: pointer;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  opacity: 0.2;
  padding: 0;
  text-shadow: 0 1px 0 #fff;
}

.exit-popup__content {
  padding: 15px 20px;
}

.exit-popup__dialog {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;

  @include range-bp(null, $screen-xs) {
    padding: 0 10px;
  }
}

.exit-popup__label {
  margin: 10px 0 15px;
}

.exit-popup__heading {
  @include font-with-fallback(Gotham-Medium, 20px);

  margin-bottom: 13px;
}

.exit-popup__social-media {
  padding-left: 40px;
  padding-right: 40px;
}

.exit-popup__wrap {
  background: #fff;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 385px;

  @include range-bp(null, $screen-xs) {
    width: 100%;
  }
}

.exit-popup__other-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 35px;
  padding-bottom: 20px;
}

.exit-popup__other-links a {
  @include font-with-fallback("Gotham-Medium");

  align-items: center;
  border: 1px solid #d4d3cd;
  border-radius: 3px;
  color: #337ab7;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  padding: 8px 10px;
  text-decoration: none;

  svg {
    margin-right: 5px;
    width: 17px;
  }
}


.feature-tab__action-btn {
    text-align: center;
    padding: 50px 20px 0;
}
.feature-tab__action-btn-anchor {
    @include font-with-fallback(montserrat-semibold, 18px);

    background-color: #f7b650;
    box-shadow: 0 5px 7px rgba(71, 71, 71, 0.27);
    color: #232222;
    padding: 26px 48px;
    text-transform: none;
    text-decoration: none;

    @include range-bp($screen-xs, 410px) {
      display: block;
      margin: 0 auto;
      max-width: 300px;
      padding: 18px;
    }

    @include bp-tn() {
      @include font-size(16px);
      display: block;
      line-height: 26px;
      margin: 0 auto;
      max-width: 300px;
      padding: 20px 15px;
    }

    &:hover,
    &:focus {
      color: #fff;
      text-decoration: none;
    }
}

.feature-tab__box {
    margin: 0 15px;
    text-align: center;
    text-decoration: none;

    @include range-bp($screen-md, null) {
        flex: 1 0 0;
    }

    @include range-bp(null, $screen-sm-max) {
        margin: 0 0 45px;
    }
}

.feature-tab__box:hover,
.feature-tab__box:focus {
    text-decoration: none;
}

.feature-tab__box:last-child {
    @include range-bp(null, $screen-sm-max) {
        margin: 0;
    }
}

.feature-tab__box-icon {
    height: 100px;
}

.feature-tab__box-icon-img {
    height: 82px;
}

.feature-tab__box-title {
    @include font-with-fallback(montserrat-medium, 22px);

    color: #2167a6;
    font-weight: normal;
    margin: 10px 0 20px;
}

.feature-tab__box-title-number {
    @include font-with-fallback(montserrat-medium);

    font-weight: normal;
}

.feature-tab__box-desc {
    @include font-with-fallback(montserrat-regular, 18px);

    color: #232222;
    line-height: 36px;
    margin: 0;

    @include range-bp(null, $screen-xs) {
        @include font-size(16px);

        line-height: 30px;
    }
}

.feature-tab__boxes {
    cursor: pointer;
    display: flex;
    margin-bottom: 30px;
  
    @include range-bp(null, $screen-sm-max) {
        flex-direction: column;
    }
}

.feature-tab__tab-header {
    @include font-with-fallback(montserrat-regular, 38px);

    color: #959595;
    font-weight: normal;
    line-height: 54px;
    margin: 0;

    @include range-bp($screen-ss, $screen-sm) {
        @include font-size(32px);
    }

    @include range-bp($screen-xs, $screen-ss) {
        @include font-size(28px);

        line-height: 40px;
    }

    @include range-bp($screen-tn, $screen-xs) {
        @include font-size(30px);

        line-height: 42px;
        padding: 0 5%;
    }
}

.feature-tab__tab.is-selected {
    @include range-bp($screen-md, null) {
        border-color: #000;
        cursor: default;
    }
}

.feature-tab__tab.is-mobile-selected {
    border-color: transparent;

    @include range-bp(null, $screen-md) {
        border-color: #000;
        cursor: default;
    }
}

.feature-tab__tab.is-selected .feature-tab__tab-header {
    @include range-bp($screen-md, null) {
        color: #232222;
        cursor: default;
    }

    @include range-bp($screen-ss, $screen-sm) {
        @include font-size(32px);
    }

    @include range-bp($screen-xs, $screen-ss) {
        @include font-size(28px);

        line-height: 40px;
    }

    @include range-bp($screen-tn, $screen-xs) {
        @include font-size(30px);

        line-height: 42px;
    }
}

.feature-tab__tab.is-mobile-selected .feature-tab__tab-header {
    @include range-bp(null, $screen-md) {
        color: #232222;
        cursor: default;
    }

    @include range-bp($screen-ss, $screen-sm) {
        @include font-size(32px);
    }

    @include range-bp($screen-xs, $screen-ss) {
        @include font-size(28px);

        line-height: 40px;
    }

    @include range-bp($screen-tn, $screen-xs) {
        @include font-size(30px);

        line-height: 42px;
    }
}

.feature-tab
    .feature-tab-content__panel.is-collapsible
    .feature-tab__tab.is-selected::after {
    @include range-bp(null, $screen-md) {
        @include icon($fa-var-chevron-down);

        color: #959595;
    }
}

.feature-tab
    .feature-tab-content__panel.is-collapsible
    .feature-tab__tab.is-mobile-selected::after {
    @include range-bp(null, $screen-md) {
        @include icon($fa-var-chevron-up);

        color: #232222;
    }
}

.feature-tab__tab-content.is-active {
    @include range-bp(null, $screen-md) {
        display: none;
    }

    @include range-bp($screen-md, null) {
        display: block !important;
    }
}

.feature-tab__tab-content.is-mobile-active {
    @include range-bp(null, $screen-md) {
        display: block;
    }

    @include range-bp($screen-md, null) {
        display: none;
    }
}

.mobile-menu{
  bottom: 0;
  height: 0;
  left: 0;
  position: absolute;
  width: 100%;

  &__accord-header{
    @include font-with-fallback(montserrat-medium);

	font-size: 16px;
    color: #232222;
    cursor: pointer;
    display: inline-block;
    line-height: 40px;
    text-transform: uppercase;
  }

  &__collapse{
    max-height: 0px;
    overflow-y: hidden;
    transition: max-height,overflow 0.5s,0.1s ease-in;

    &.is-expanded {
      max-height: unset;
      overflow: visible;
    }

    &-cont{
      padding: 10px 0px;
    }
    
    &-explore-legalmatch{
      &-header{
        @include font-with-fallback(montserrat-medium);
  
		font-size: 18px;
        color: #232222;
        font-weight: normal;
        margin: 0;
        padding: 5px 0px 0px;

        &--underline{
          text-decoration: underline;
        }

        &--strong{
          @include font-with-fallback(montserrat-semibold);
          font-weight: normal;

          @include range-bp(null, $screen-xs){
            display: block;
          }
        }
      }

      &-item{
        margin-bottom: 15px;
        position: relative;
      }
    }

    &-attorney-item{
	  font-size: 14px;
      color: #337AB7;
    }
  }

  &__column{
    display: flex;
    flex: 1 0 100%;

    @include range-bp(null, $screen-xs-max){
      flex-wrap: wrap;
    }
  }

  &__left-col{
    padding: 10px;
    width: 45%;

    @include range-bp(null, $screen-xs-max){
      margin-bottom: 0;
      width: 100%;
    }
  }

  &__elmo{

    &-category-links{
      padding-top: 7px;

      @include range-bp($screen-xs, $screen-ss-max){
        column-count: 2;
        column-gap: 20px;
      }

      &-item{
		@include font-with-fallback(montserrat-regular);
		font-size: 15px;
        color: #337ab7;
		display: inline-block;
    margin-bottom: 14px;
        text-transform: capitalize;
    width: 100%;
    
    &:last-child {
      margin-bottom: 7px;
    }

		@include range-bp(null, $screen-sm){
            display: block;
          }
      }

      @include bp-tn(){
        column-count: 1;
      }
    }

    &-dropdown{
		font-size: 14px;

	  background: #fff;
	  border: 1px solid #DADCE0;
      border-radius: 4px;
	  color: #232222;
      cursor: pointer;
      line-height: 1;
      padding: 10px 5px;
      text-align: left;
      width: 100%;

      &-caret{
        float: right;
      }

      &:after{
        display: block;
        clear: both;
        content: ' ';
		color: #5F6368;
      }

      @include bp-tn(){
        margin: 0;
        max-width: none;
      }
    }

    &-dropdown-menu{
      
		font-size: 14px;
	  background: #fff;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: scroll;
      width: 100%;

      > li > a{
		color: #232222;
        padding: 8px 5px;        
		display: block;
      }

      @include bp-tn(){
        max-width: none;
        width: 100%;

        > li > a{
          padding: 8px 5px;
          white-space: normal;
          word-wrap: break-word;
        }
      }
    }
  }

  &__header-link{
	font-size: 16px;
    display: inline-block;
    line-height: 40px;
    text-transform: uppercase;
	color: #337ab7;
    
    &:active,
    &:hover{
      text-decoration: none;
    }
  }

  &__icon{

	font-size: 20px;
    color: #232222;

    display: inline-block;
    height: 23px;
    margin: 0 6px 0 1px;
    text-align: center;
    width: 23px;

    &-balance-scale{
      background-image: url('/bd/homepage-redesign/images/svg/balance-scale-solid.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 22px;
      position: relative;
      top: 4px;

      &::before{
        content: '';
      }
    }
    &-user-friends{
      background-image: url('/bd/homepage-redesign/images/svg/user-friends-solid.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 22px;
      position: relative;
      top: 4px;

      &::before{
        content: '';
      }
    }
  }

  &__link{
    display: block;
    line-height: 25px;
    padding: 5px;
    text-transform: capitalize;
  }

  &__login-cont{
    display: flex;
    margin-bottom: 20px;

    &-left{
      flex: 1 0 40%;
    }
    &-right{
      flex: 1 0 60%;
    }

    @include range-bp(null, $screen-xs-max){
      flex-wrap: wrap;

      &-left,
      &-right{
        flex: 1 0 50%;
      }
    }
  }

  &__lm-homepage,
  &__lm-accordion{
    border-top: 1px solid #dbdbdb;
  }

  &__main{
    @include font-with-fallback(montserrat-medium);

    background: #fff;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &-list{
      list-style: none;
      margin: 0;
      padding: 0 10px;
      width: 100%;

      &-item{
        min-height: 40px;
        padding: 3px;
		&.js-open {
			.mobile-menu {
				&__collapse {
					max-height: none;
					overflow: visible;
				}
			}
			.js-menu-icon {
				&::before {
					content: '\f107';
					font-weight: bolder;
				}
			}
		}
		.js-menu-icon {
			&::before {
				content: '\f105';
				font-weight: bolder;
			}
		}
      }
    }
  }

  &__right-col{
    padding: 10px;
    width: 55%;

    @include range-bp(null, $screen-xs-max){
      width: 100%;
    }
  }

  &__overlay{
    background: rgba(0,0,0,.3);
    left: 0;
    position: absolute;
    overflow-x: hidden;
    right: 0;
    top: 0;
  }

  &__search{
    padding: 10px 10px 0;
    
    &-form-cont{
      display: flex;
      flex-direction: row;
      height: 37px;
      margin-left: 2px;
      overflow: hidden;
    }

    &-input{
	  font-size: 17px;

      border: 1px solid #bab6b6;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      flex: 1 100%;
      height: 37px;
      padding: 0 15px;
    }

    &-submit{
	  font-size: 23px;

      background: transparent;
      border: none;
      color: #9b9797;
      cursor: pointer;
      min-height: 37px;
      text-align: center;
      width: 46px;

      &:disabled{
        cursor: not-allowed;
      }

      &:focus{
        outline: none;
      }
    }
  }
}
.header-wrapper {
	.mobile-menu{
		&__header-link, &__elmo-category-links-item, &__link{
			color: #337ab7;
		}
	}
}


.mobile-menu__elmo-container
.mobile-menu__elmo-dropdown:after {
	@include icon($fa-var-angle-down, 22px);
    color: #5F6368;
    font-weight: bolder;
    position: absolute;
    right: 15px;
    top: 7px;
}
.mobile-menu__elmo-container.open
.mobile-menu__elmo-dropdown:after {
	@include icon($fa-var-angle-up, 22px);
    color: #5F6368;
    font-weight: bolder;
    position: absolute;
    right: 15px;
    top: 7px;
}

.logo__image {
    height: 41px;
    width: 201px;
}

@include range-bp(null, $screen-ss-max){
    .logo--mobile{
        display: flex;
        flex-flow: row nowrap;
        max-width: none;

        .logo__link-container{
            flex: 1 85%;
        }
        .logo__link{
            display: inline-block;
            text-align: left;
        }
    }
    
    .logo__hamburger-menu{
        background: #fff;
        border-radius: 4px;
        cursor: pointer;
        display: inline;
        height: 30px;
        margin-top: 4px;
        padding: 5px;
        width: 35px;

        &-icon {
            &-cont {
                display: block;
                height: 100%;
                position: relative;
                width: 100%;

                > span {
                    background-color: #232222;
                    display: block;
                    height: 3px;
                    position: absolute;
                    transition: all 0.25s ease;
                    width: 50%;

                    &:nth-child(1) {
                        left: 0;
                        top: 0;
                    }

                    &:nth-child(2) {
                        right: 0;
                        top: 0;
                    }

                    &:nth-child(3) {
                        left: 0;
                        margin-top: -1.5px;
                        top: 50%;
                    }

                    &:nth-child(4) {
                        margin-top: -1.5px;
                        right: 0;
                        top: 50%;
                    }

                    &:nth-child(5) {
                        bottom: 0;
                        left: 0;
                    }

                    &:nth-child(6) {
                        bottom: 0;
                        right: 0;
                    }

                    &:nth-child(7) {
                        left: 0;
                        margin-top: -1.5px;
                        opacity: 0;
                        top: 50%;
                        transform: rotate(-45deg);
                        width: 100%;
                    }

                    &:nth-child(8) {
                        left: 0;
                        margin-top: -1.5px;
                        opacity: 0;
                        top: 50%;
                        transform: rotate(45deg);
                        width: 100%;
                    }

                    &:nth-child(1),
                    &:nth-child(3),
                    &:nth-child(5) {
                        -moz-border-radius-bottomleft: 1.5px;
                        -moz-border-radius-topleft: 1.5px;
                        -webkit-border-bottom-left-radius: 1.5px;
                        -webkit-border-top-left-radius: 1.5px;
                        border-bottom-left-radius: 1.5px;
                        border-top-left-radius: 1.5px;
                    }

                    &:nth-child(2),
                    &:nth-child(4),
                    &:nth-child(6) {
                        -moz-border-radius-bottomright: 1.5px;
                        -moz-border-radius-topright: 1.5px;
                        -webkit-border-bottom-right-radius: 1.5px;
                        -webkit-border-top-right-radius: 1.5px;
                        border-bottom-right-radius: 1.5px;
                        border-top-right-radius: 1.5px;
                    }
                }
            }
        }

        &.open {
            .logo__hamburger-menu-icon {
                &-cont {
                    @at-root -bar {
                        opacity: 0;

                        &:nth-child(1) {
                            transform: rotate(45deg);
                        }

                        &:nth-child(2) {
                            transform: rotate(-45deg);
                        }

                        &:nth-child(3) {
                            left: -50%;
                        }

                        &:nth-child(4) {
                            right: -50%;
                        }

                        &:nth-child(5) {
                            transform: rotate(-45deg);
                        }

                        &:nth-child(6) {
                            transform: rotate(45deg);
                        }

                        &:nth-child(7),
                        &:nth-child(8) {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
.w-top-rated__modal {
    &-header{
        border-bottom: 0;
        margin: 20px 20px 0;
        padding: 0;
        text-align: left;
    }

    &-header-cont{
        padding: 0 15px;
    }

    &-header-info{
        display: flex;

        @include range-bp(null, $screen-xs-min){
            display: block;
        }
    }

    &-photo{
        padding-right: 20px;

        &-circle{
            border-radius: 50%;
            width: 100px;
        }
    }

    &-attorney-name {
        @include font-size(24px);

        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    &-attorney-text-muted{
        color: #777777;
        display: inline-block;
        font-weight: bold;
        margin-bottom: 5px;
        max-width: 100%;
    }

    &-attorney-category{
        margin: 0 0 10px;
    }

    &-attorney-rating{
        display: flex;
        padding-bottom: 10px;
        padding-top: 30px;

        @include range-bp(null, $screen-xs-min){
            display: block;
        }
    }

    &-rating-title{
        margin-right: 10px;
    }

    &-rating{
        margin-right: 5px;

        @include range-bp(null, $screen-xs-min){
            display: block;
        }

        .fa-star-o{
            color: #666666;
            &::before{
                @include icon($fa-var-star);
            }
        }

        .w-top-rated__star:not(:first-child){
            margin-left: 0;
        }
    }

    &-body{
        max-height: 370px;
        overflow-y: auto;
        padding: 0 25px;
    }

    &-rating-container{
        border-bottom: 1px solid;
        border-top: 1px solid;
        padding: 15px 0;
        text-align: left;

        &-text{
            margin: 0;
        }
    }

    &-rating-row{
        display: flex;
        padding: 5px 15px;

        @include range-bp(null, 620px){
            display: block;
        }
    }

    &-rating-column-1{
        width: 340px;

        @include range-bp(null, 620px){
            width: auto;
        }
    }

    &-comment-cont{
        padding: 30px 0 0;
    }

    &-comment-item{
        border-bottom: 1px solid #CCC;
        margin-bottom: 20px;
        padding-bottom: 10px;
        text-align: left;

        &:last-child{
            border-bottom: 0;
        }
    }

    &-footer{
        border-top: 1px solid #efefef;
        padding: 15px;
        text-align: right;
    }

    &-close{
        border: 0;
        border-radius: 4px;
        font-size: 14px;
        line-height: 1.42857;
        padding: 6px 12px;
        outline: none;

        &:active{
            background-image: none;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            outline: 0;
        }
    }
}




.service-menu__item {
    @include font-with-fallback(gotham-book, 14px);

    &:nth-child(2) {
        @include range-bp($screen-xs, $screen-ss-max) {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }

    &::before {
        @include icon($fa-var-circle, 6px);
    }

    &:first-child::before {
        content: none;
    }

    &:nth-child(2)::before {
        @include range-bp(null, $screen-sm-max) {
            @include icon($fa-var-circle);
        }
    }

    &:not(:first-child)::before {
        @include range-bp($screen-xs, $screen-ss-max) {
            margin-right: 15px !important;
        }
    }
}

.service-menu__link {
    @include range-bp(null, $screen-ss-max) {
        display: inline-block;
        line-height: 48px;
    }
}

.footer {
  color: #97a0a7;
}

.footer__contact {
  @include font-size(14px);

  line-height: 1.8em;
  order: 4;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;

  @include range-bp($screen-md) {
    padding-bottom: 40px;
  }
}

.footer__contact-list {
  list-style: none;
  margin: 0 0 10px 0;
  padding: 0;
}

.footer__contact-list-item {
  display: inline;
  padding-right: 20px;

  &::before {
    content: '|';
    margin-right: 20px;
  }

  &:first-child::before {
    content: none;
  }

  &:last-child {
    padding-right: 0;
  }
}

.footer__contact-address{
  white-space: nowrap;
}

.footer__contact-list-item-link {
  color: #97a8a7;

  &:hover {
    color: #23527c;
  }
}

.footer__copyright {
  @include font-size(12px);

  border-top: 1px solid #eee;
  line-height: 1.8em;
  order: 4;
  padding: 30px 15px 95px;
  width: 100%;
}

.footer__copyright-text{
  white-space: nowrap;
}

.footer__flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 50px;
}

.footer__icons {
  flex: 0 0 100%;    
  order: 2;

  @include range-bp($screen-xs) {
    flex: 1 1 auto;
    padding-left: 20px;
  }

  @include range-bp($screen-sm+20px) {
    order: 3;
  }
}

.footer__logo {
  flex: 0 0 100%;
  margin-bottom: 28px;
  order: 1;

  .logo__image{
    height: 60px;
  }

  @include range-bp($screen-xs) {
    flex: 1 1 auto;
    margin-bottom: 10px;
    padding-right: 20px;
  }

  @include range-bp($screen-sm+20px) {
    margin-bottom: 0;
  }
}

.footer__menu {
  flex: 0 0 100%;
  margin: 20px 0;
  order: 3;

  @include range-bp($screen-ss) {
    margin-bottom: 30px;
  }

  @include range-bp($screen-sm+20px) {
    flex: 2 1 auto;
    margin: 40px 0;
    order: 2;
  }

  @include range-bp($screen-md) {
    flex: 2 1 auto;
    margin: 30px 0 60px;
    order: 2;
  }
}
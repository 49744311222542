.page{
  @include font-with-fallback(montserrat-regular,14px);

  background: #FFF;
  color: #232222;

  &__container{
    padding: 60px 0px;

    &-first{
      background: #fff;
      padding-top: 80px;

      @include range-bp(null, $screen-sm){
        padding-top: 60px;
      }
    }

    @include range-bp(null, $screen-xs){
      padding: 40px 0px;

      &-first{
        padding-top: 30px;
      }
    }
  }

  &__footer {
    @include font-with-fallback(gotham-book);

    background: #fff;
    border-top: 1px solid #dfe2e4;
    margin-top: 90px;
  
    @include range-bp(null, $screen-xs){
      margin-top: 60px;
    }
  }

  &__testimonials {
    background: #fff;
  }
}
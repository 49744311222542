.w-find-lawyers__findby {
  &.is-active{
    padding-bottom: 0px;
  }

  &-tab {
    padding: 60px 0 0px;

    @include range-bp(null, $screen-xs-max){
      padding: 40px 0 0px;
    }
  }

  &-panel-item {
    width: 30%;

    &:hover {
      @include font-with-fallback(montserrat-medium);
    }

    &.is-selected{
      @include font-with-fallback(montserrat-medium);

      &:hover {
        @include font-with-fallback(montserrat-medium);
      }
    }

    &-link {
      @include font-size(28px);
  
      @include range-bp(null, $screen-md-max) {
        @include font-size(22px);
      }
    }
  }
}

.w-find-lawyers__header {
  @include font-with-fallback(montserrat-light);
}

.w-find-lawyers__link {
  @include font-with-fallback(montserrat-medium, 22px);

  color: #2167a6;
  line-height: 40px;

  @include bp-md(){
    @include font-size(20px);
    line-height: 34px;
  }

  @include range-bp(null, $screen-ss-max){
    @include font-size(18px);
    line-height: 20px;
  }

  &-wrap{
    font-weight: normal;
    margin: 0;
  }
}

.w-find-lawyers__list {
	padding: 15px 0;
	border-top: 1px #e5e5e5 solid;

	&.is-expanded {
		.w-find-lawyers__item {
			display: inline-block;
		}
	}

	&:first-child {
		border-top: none;
	}

	@include range-bp($screen-md, null) {
		column-count: 3;
		column-gap: 0;
	}

	@include range-bp($screen-xs, $screen-sm-max) {
		column-count: 2;
	}

	@include bp-tn() {
		column-count: 1;
	}
}

.w-find-lawyers__list-title {
	color: #232222;
	padding-bottom: 10px;
	padding-left:43px;
}

.w-find-lawyers__item {
	padding-left: 10%;

	&:nth-child(n+16){
		display: none;
	}

	@include range-bp($screen-ss, $screen-sm-max) {
		&:nth-child(n+11){
			display: none;
		}
	}

	@include bp-xs() {
		&:nth-child(n+9){
			display: none;
		}
	}

	@include bp-tn() {
		&:nth-child(n+6){
			display: none;
		}
	}

	@include range-bp($screen-xs,$screen-md){
		padding-left: 7%;
	}

	&--spacer{
		padding: 0;
	}
}

.w-find-lawyers__item-other-cat {
	display: block !important;
}

.w-find-lawyers__item--issue {
	&:nth-child(n+16){
		display: block;
	}

	@include range-bp($screen-ss, $screen-sm-max) {
		&:nth-child(n+11){
			display: block;
		}
	}

	@include bp-xs() {
		&:nth-child(n+9){
			display: block;
		}
	}

	@include bp-tn() {
		&:nth-child(n+6){
			display: block;
		}
	}
}

.w-find-lawyers__item-other-cat {
	@include bp-tn() {
		&:nth-child(n+6){
			display: block;
		}
	}
}

.w-find-lawyers__sub {
  @include font-with-fallback(montserrat-regular, 14px);

  color: #878787;
  display: block;
  text-transform: uppercase;

  @include range-bp(null, $screen-ss-max){
    @include font-size(10px);
    margin-top: 5px;
  }
}
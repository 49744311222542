.random-pop-up {
    background: white;
    border-radius: 4px;
    bottom: 50px;
    box-shadow: 0 0 20px #00000057;
    display: inline-block;
    max-width: 400px;
    padding: 20px 25px;
    position: fixed;
    right: -500px;
    transition: 1s;
    z-index: 2;

    @include range-bp(null, $screen-md) {
        right: -500px;
    }

    @include range-bp(null, $screen-sm) {
        bottom: -500px;
        left: 20px;
        max-width: unset;
        padding: 13px;
        width: calc(100% - 40px);
    }
}

.random-pop-up_slide {
    right: 100px;

    @include range-bp(null, $screen-md) {
        right: 40px;
    }

    @include range-bp(null, $screen-sm) {
        bottom: 30px;
        right: 20px;
    }
}

.random-pop-up__flex {
    align-items: center;
    display: flex;

    @include range-bp(null, $screen-sm) {
        align-items: flex-start;
    }
}

.random-pop-up__time {
    @include font-size(14px);

    color: #696969;
    display: block;
    font-family: "Gotham-Book", Arial, sans-serif;
    margin-top: 5px;
}

.random-pop-up__icon {
    margin-right: 20px;

    @include range-bp(null, $screen-sm) {
        height: 50px;
        margin-right: 10px;
        max-width: 50px;

        img {
            width: 100%;
        }
    }
}

.random-pop-up__text {
    @include font-size(18px);

    color: #696969;
    font-family: "Gotham-Book", Arial, sans-serif;
    margin: 0;

    b {
        color: #02397b;
        font-family: "Gotham-Medium", Arial, sans-serif;
    }
}

.random-pop-up__link {
    @include font-size(16px);

    background: #02397b;
    border-radius: 3px;
    color: white;
    display: block;
    font-family: "Gotham-Medium", Arial, sans-serif;
    margin-top: 15px;
    padding: 17px;
    text-align: center;
    text-decoration: unset;

    @include range-bp(null, $screen-sm) {
        font-size: 17px;
        padding: 15px;
    }

    &:hover,
    &:focus {
        background: #02397b;
        color: white;
        text-decoration: unset;
    }
}

@import "@bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "@bootstrap-sass/assets/stylesheets/bootstrap/mixins";

@import "@font-awesome/scss/variables";
@import "@font-awesome/scss/mixins";

@import "@poly-fluid-sizing";

@import "common/variables";
@import "common/font-utils";
@import "common/grid-mixins";
@import "common/grid-rules";
@import "common/gotham-fonts";
@import "common/montserrat";
@import "common/icon";
@import "common/placeholder";

// base code
@import "bem/autocomplete/base";
@import "bem/copyright/base";
@import "bem/exit-popup/base";
@import "bem/explore-lm/base";
@import "bem/header/base";
@import "bem/header/dropdown-shared";
@import "bem/logo/base";
@import "bem/modal-generic/base";
@import "bem/modal-iframe/base";
@import "bem/other-categories/base";
@import "bem/overlay/base";
@import "bem/search/base";
@import "bem/service-menu/base";
@import "bs4/social-media-auto-like/base";
@import "bem/trust-icons/base";
@import "bem/w-find-lawyers/base";

// LMS-6069 Homepage redesign

@import "bem/carousel/base";
@import "bem/case-intake-form/modal";
@import "bem/case-intake-form/home";
@import "bem/case-posted-count/base";
@import "bem/content/home";
@import "bem/feature-tab/base";
@import "bem/footer/home";
@import "bem/footer/plan3097";
@import "bem/footer-secondary/base";
@import "bem/footer-tertiary/base";
@import "bem/header/home";
@import "bem/hero/home";
@import "bem/intro/home";
@import "bem/logo/home";
@import "bem/mobile-menu/base";
@import "bem/other-categories/home";
@import "bem/page/home";
@import "bem/service-menu/home";
@import "bem/slider/base";
@import "bem/social/base";
@import "bem/sticky-header/base";
@import "bem/sticky-header/home";
@import "bem/tab-content/base";
@import "bem/trust-bar/base";
@import "bem/user-nav-menu/loading";
@import "bem/w-find-lawyers/home";
@import "bem/w-testimonials/base";
@import "bem/w-top-rated/home-variant";
@import "bem/w-top-rated/modals";
@import "bem/blur-active-element/base";
@import "bem/random-pop-up/base";

//PLAN 4934

@import "bem/w-find-lawyers/home-plan4934";

// LMS-25589
@import "bem/scroll-to-top/base";

// overrides bootstrap default
// sets base font-size for `rem` units
// note: `poly-fluid-sizing` can only work with `px` sizes
html {
    font-size: 16px;
}

//overwrite because specs needs full width up to 1400px
@include range-bp($screen-sm, null) {
    .container {
        max-width: 1400px;
        padding: 0 22px !important;
        width: 100% !important;
    }
}

@include bp-tn() {
    .container {
        padding-left: 10px;
        padding-right: 10px;
    }
}

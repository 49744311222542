.social {
  list-style: none;
  margin-top: 10px;
  padding: 0;
}

.social__icon {
  @include font-size(18px);

  background: #fff;
  border: 3px solid #666666;
  border-radius: 50%;
  color: #666666;
  display: inline-block;
  height: 40px;
  text-align: center;
  width: 40px;

  .fa {
    line-height: 34px;
  }
}

.social__item {
  border-radius: 50%;
  display: inline-block;
}

.social__wrapper {
  justify-self: end;
  text-align: right;

  @include range-bp(null, $screen-sm-max) {
    width: 240px;
  }
}
.w-find-lawyers__plan-4934{
    padding-top: 0px;
    font-family: Gotham-Book,Arial,sans-serif;

    .w-find-lawyers__list{
        column-count: 4;

        @include range-bp($screen-ss-min, $screen-md) {
            column-count: 3;
        }

        @include range-bp($screen-tn-min, $screen-ss-max) {
            column-count: 2;
        }

        @include range-bp(null, $screen-tn-max) {
            column-count: 1;
        }
    }
    .w-find-lawyers__item{
        padding-left: 0%;
    }
    .w-find-lawyers__item {
        display: block;
                
        @include range-bp(null,  $screen-ss-max) {
            display: block !important;
        }
    }
    .w-find-lawyers__link{
        font-size: 1rem;
        cursor: pointer;
        line-height: 22.5px;
        text-decoration: none;
        text-transform: capitalize;
        font-family: Gotham-Book,Arial,sans-serif;
    }

    .w-find-lawyers__nav-text{
        font-size: 1.0625rem;
        border-bottom: 1px solid #f0f1f2;
        color: black;
        margin-bottom: 20px;
        padding: 15px 0;
        text-align: left;
        width: 100%;
        font-weight: 400;
        line-height: 22.5px;

        @include range-bp(null,  $screen-ss-max) {
           font-size: 25px;
           text-align: center;
        }
    }

    .tab-content__expander{
        border-bottom: 0px;
        padding-top: 15px;
    }

    .tab-content__expander-btn{
        font-size: 1rem;

        @include range-bp(null,  $screen-ss-max) {
            font-size: 16px;
            padding-left: 0px;
            text-align: center;
            width: 75%;
        }
    }
}


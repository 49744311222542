.button-reset {
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  outline: none;
  padding: 0;
  width: auto;
}

@function vr($value){
  @return (24 * $value)+px;
}

.carousel-block {
  margin: auto;
  position: relative;
  width: auto;
}

.carousel-slider {
  margin-bottom: 20px;

  &__slide-list {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
    transform: translateX(0);
    transition: transform 0.4s ease;
  }

  &__slide {
    flex: 1 0 100%;

    img {
      display: block;

      @include range-bp(null,$screen-ss){
        max-width: none;
        width: 100%;
      }
    }
  }
}

.carousel-dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0px;

  &__dot {
    @extend .button-reset;

    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 13px;
    margin: 0 vr(0.5);
    transition: background-color 0.4s ease;
    width: 13px;

    &--active {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.carousel-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  width: 100%;

  &__button {
    @extend .button-reset;

    background-color: rgba(0, 0, 0, 0.1);
    color: #fff;
    height: vr(2);
    width: vr(3);
  }
  @include bp-tn(){
    display: none;
  }
}

.carousel-controls__button {
  background: none;
  color: #abaaaa;
  height: 81px;
  position: relative;
  transform: translateY(-50%);
  width: auto;
}

.carousel-controls__prev {
  left: -10%;

  @include bp-ss() {
    left: -12%;
  }

  &::before {
    @include font-size(60px);
    @include icon($fa-var-angle-left);
    font-weight: bold;

    @include range-bp(null, $screen-ss) {
      @include font-size(40px);
    }

  }

}

.carousel-controls__next {
  right: -10%;

  @include bp-ss() {
    right: -12%;
  }

  &::before {
    @include font-size(60px);
    @include icon($fa-var-angle-right);
    
    font-weight: bold;

    @include range-bp(null, $screen-ss) {
      @include font-size(40px);
    }

  }

}
.w-top-rated {
    background: #fff;
    padding-top: 37px;
    position: relative;

    &::after {
        background: transparent;
        bottom: -8px;
        box-shadow: 0px 8px 9px -6px rgba(71, 71, 71, 0.14) inset;
        content: '';
        display: block;
        height: 8px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 2;
    }

    @include range-bp($screen-md) {
        padding-top: 43px;
        padding-bottom: 37px
    }
}

.w-top-rated--no-location {
    background-color: #f4f8ea;
    
    .w-top-rated__location {
        display: none;
    }
}

.w-top-rated__carousel {
    @include range-bp($screen-md) {
        margin: 0;
    }
}

.w-top-rated__category {
    @include font-with-fallback(montserrat-medium, 18px);

    color: #202124;
    font-weight: normal;
    margin: 3px 0 0;
}

.w-top-rated__info {
    padding-left: 21px;
}

.w-top-rated__header {
    @include font-with-fallback(montserrat-medium, 21px);

    font-weight: normal;
    margin: -4px 0 29px;
    text-align: center;

    @include range-bp(null, $screen-sm-max) {
        line-height: 32px;
    }

    @include range-bp($screen-md) {
        @include font-size(23px);

        margin: 2px 0 43px;
    }
}

.w-top-rated__header-sub {
    white-space: nowrap;
}

.w-top-rated__home-carousel-controls {
    display: none;
}

.w-top-rated__home-carousel-dots {
    margin: 32px 0 31px;

    @include range-bp($screen-md) {
        display: none;
    }
}

.w-top-rated__home-item {
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 20px;

    @include range-bp(null, $screen-sm-max) {
        justify-content: center;
    }

    @include range-bp($screen-md) {
        padding: 0;
        position: static;
        visibility: visible !important;
        width: auto;
    }
}

.w-top-rated__home-list {
    @include range-bp($screen-md) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.w-top-rated__location {
    color: #939190;
    margin-bottom: 5px;
}

.w-top-rated__name {
    @include font-with-fallback(montserrat-medium, 24px);

    color: #144e8a;
    font-weight: normal;
    margin: 0;
}

.w-top-rated__photo {
    background-size: cover;
    border-radius: 50%;
    flex: 0 0 90px;
    height: 90px;
    width: 90px;
}

.w-top-rated__rating {
    margin-top: 6px;
}

.w-top-rated__star {
    @include font-size(20px);

    color: #dc8721;
}

.w-top-rated__star--with-spacing {
    margin-right: 10px;
}
.sticky-header {
    background-color: #fff;
    border-bottom: 2px solid #909191;
    box-shadow: 0 3px 9px rgba(71, 71, 71, 0.3);
    display: none;
    position: fixed;
    top: 0;
    transition: display 0.4s ease;
    width: 100%;
    z-index: 1000;
  
    &__body {
      display: flex;
      justify-content: space-between;
      padding: 0 5%;
    
      @include range-bp(null, $screen-xs-max) {
        display: block;
        text-align: center;
      }
  
      @media screen and (min-width:768px) {
        &.plan-5126{
            background: linear-gradient(90deg, #576DC6 0%, #3F57B8 46.15%, #2C3C80 100%);
        }
      }
    }
  
    &__button {
      position: relative;
    
      &-anchor {
        @include font-with-fallback('Gotham-Medium');
        @include poly-fluid-sizing('font-size', ($screen-lg: 24px, $screen-md: 16px, $screen-sm-max: 16px));
        @include poly-fluid-sizing('margin-top', ($screen-lg: 16px, $screen-md: 13px, $screen-sm-max: 10px));
        
        background-color: #f7b650;
        border-radius: 3px;
        color: #232222;
        display: block;
        padding: 17px 38px 16px 22px;
        position: relative;
        text-decoration: none;
    
        @include range-bp($screen-md, null){
          padding: 15px 45px 14px 20px;
        }
    
        @include bp-xs(){
          margin: 14px auto;
          width: 290px;
        }
    
        @include bp-tn() {
          margin: 14px auto;
          width: 240px;
        }
    
        &::after {
          @include icon($fa-var-angle-right);
          @include poly-fluid-sizing('font-size', ($screen-lg: 24px, $screen-md: 22px, $screen-sm-max: 20px));
          @include poly-fluid-sizing('line-height', ($screen-lg: 24px, $screen-md: 22px, $screen-sm-max: 20px));
          @include poly-fluid-sizing('top', ($screen-lg: 20px, $screen-md: 15px, $screen-sm-max: 18px));
          
          font-weight: bold;
          margin-left: 10px;
          position: absolute;
        }
  
        &:hover {
          color: #232222;
          text-decoration: none;
        }
    
      }
  
      &-maroon {
        background-color: #9A364A;
        color: #ffffff;
  
        &:focus {
          color: #ffffff;
        }
    
        &:hover {
          color: #ffffff;
          text-decoration: none;
        }
      }
      
      &-shake-left-right {
        animation: shake 5s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        perspective: 1000px;
        animation-iteration-count: infinite;
      }
  
      @keyframes shake { 
        10%, 90% { 
          transform: translate3d(-1px, 0, 0); 
        }
  
        20%, 80% { 
          transform: translate3d(2px, 0, 0); 
        }
  
        30%, 50%, 70% { 
          transform: translate3d(-2px, 0, 0); 
        }
  
        40%, 60% { 
          transform: translate3d(2px, 0, 0); 
        }
      }
    }
  
    &__text {
      @include font-with-fallback('Gotham-Medium');
      @include poly-fluid-sizing('font-size', ($screen-lg: 30px, $screen-md: 22px, $screen-sm-max: 18px));
      @include poly-fluid-sizing('line-height', ($screen-lg: 30px, $screen-md: 22px, $screen-sm-max: 18px));
      @include poly-fluid-sizing('padding-top', ($screen-lg: 32px, $screen-md: 30px, $screen-sm-max: 28px));
      @include poly-fluid-sizing('padding-bottom', ($screen-lg: 32px, $screen-md: 30px, $screen-sm-max: 28px));
    
      color: #232222;
      font-weight: normal;
      line-height: 24px;
    
      @include range-bp(null, $screen-xs-max) {
        display: none;
      }
    }
  
    &--blue, &--dark-blue{
      background-color: #689acb;
      border: 0;
  
      &__text{
        color: #fff;
      }
    }
    
    &--dark-blue{
      background-color: #323f83;
    }

    &.sticky-header--yellow{
      background: linear-gradient(90deg, #F7B650 0%, #F5B554 51.62%, #DC8721 100%) !important;
      border-bottom: 0 !important;

      .sticky-header__body{
        background: linear-gradient(90deg, #F7B650 0%, #F5B554 51.62%, #DC8721 100%)  !important;

        .sticky-header__text{
          color: #232222;
        }

        .sticky-header__button-anchor{
          background-color: #02397b;
          color: #ffffff;
        }
      }
    }
  }

  .sticky-header__body.sticky-header--yellow{
    background: linear-gradient(90deg, #F7B650 0%, #F5B554 51.62%, #DC8721 100%) !important;

    .sticky-header__text{
      color: #232222 !important;
    }
  }


  .sticky-header__body.cta-desktop{
    display: flex;
  }
  
  .sticky-header__body.cta-mobile{
    display: none;
  }

  @media screen and (max-width:767px) {
    .sticky-header__body.cta-desktop{
        display: none;
    }
    
    .sticky-header__body.cta-mobile{
        display: flex;
    }
  }
  
  @media screen and (max-width:575px) {
    .sticky-header__body.cta-mobile{
        display: block;
    }
  }

.sticky-header--gold {
  background: linear-gradient(90deg, #F7B650 0%, #F5B554 51.62%, #DC8721 100%);
  border: 0;
}

.sticky-header--gold__body {
  background: linear-gradient(90deg, #F7B650 0%, #F5B554 51.62%, #DC8721 100%);
} 

.sticky-header--gold__text {
  color: #232222;
} 

.sticky-header--gold__button-anchor {
  background: #9A364A;
  color: #ffffff;
}

// to be clean up once the variant will be applied to all city-states pages
.postid-2388 .sticky-header {
  background: #fabf68 !important;
}

.postid-2388 .sticky-header__button-anchor {
  background: #042463 !important;
  color: #ffffff !important;
}
//--
.other-categories__list {
  display: block;
  column-count: 2;
  list-style: none;
  margin: 0;
  padding: 0;

  @include range-bp(null, $screen-ss) {
    column-count: unset;
  }
}

.other-categories__item {
  break-inside: avoid;
}

.other-categories__link {
  @include font-size(14px);

  color: #1864af;
  cursor: pointer;
  display: inline-block;
  padding: .3rem 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.other-categories__modal-body {
  padding: 25px 20px;
}

.other-categories__modal-content {
  border: none;
  box-shadow: none;
}

.other-categories__modal-dialog {
  max-width: 755px;
}

.other-categories__title {
  @include font-with-fallback(Gotham-Medium);
  @include poly-fluid-sizing('font-size', ($screen-tn: 21px, $screen-ss: 24px));

  color: #2b2c2d;
  font-weight: normal;
  margin: 0 0 0.5em;
  text-transform: uppercase;
}

.other-categories__subtitle {
  @include font-size(14px);

  color: #000;
  font-weight: normal;
  margin: 0 0 0.5em;
  text-transform: uppercase;
}

.other-categories__wrapper {
  max-height: 400px;
  overflow-y: scroll;

  @include mobile-devices-landscape { 
    max-height: 200px;
  }
}

.intro{
  text-align: center;
  
  &__body {
    color: #232222;
    display: flex;
    max-width: 100%;
    padding-bottom: 0px;

    @include range-bp(null, $screen-sm-max) {
      flex-direction: column;
    }

    &-content{
      margin-bottom: 0px;
      
      &-link {
        @include font-with-fallback(montserrat-medium);

        color: #2167a6;
        text-decoration: underline;

        &--block {
          @extend .intro__body-content-link;
    
          @include range-bp(null, $screen-sm-max) {
            display: block;
            margin-top: 20px;
            text-align: center;
          }
        }
      }
    }

    &-content,
    &-content--center {
      @include font-with-fallback(montserrat-regular, 18px);

      line-height: 34px;
      margin-top: 8px;

      @include range-bp(null, $screen-sm-max){
        text-align: center;
      }

      @include range-bp(null, $screen-xs){
        @include font-size(16px);
        line-height: 30px;
      }

    }

    &-content--canada {
      @include range-bp($screen-sm){
        margin-top: 0;
      }
    }

    &-item {
      margin-right: 130px;
      text-align: left;

      @include bp-md(){
        margin-right: 65px;
      }

      @include range-bp(null, $screen-sm-max) {
        margin-bottom: 30px;
        margin-right: 0;
        margin-top: 30px;
      }

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        @include range-bp(null, $screen-sm-max) {
          margin-top: 30px;
        }
      }

    }

    &-title {
      @include font-with-fallback(montserrat-medium, 21px);
      line-height: 30px;

      @include range-bp(null, $screen-sm-max) {
        text-align: center;
      }

      &--emphasize {
        @include font-with-fallback(montserrat-medium, 22px);
        font-weight: normal;
        line-height: 32px;
        margin: 0;
  
        @include range-bp(null, $screen-sm-max) {
          text-align: center;
        }
      }
    }
  }

  &__header {
    @include font-with-fallback(montserrat-light, 38px);
    
    color: #232222;
    line-height: 54px;
    font-weight: normal;
    margin-bottom: 60px;
    margin-top: 0px;

    @include range-bp(null, $screen-ss){
      @include font-size(30px);
      line-height: 42px;
      margin-bottom: 10px;
    }
  }

  &__header--canada {
    margin-top: 30px;
    
    @include range-bp($screen-sm){
      @include font-size(28px);
      margin-bottom: 0;
      margin-top: 70px;
    }
  }

  &--home {
    @include range-bp(null, $screen-xs-max){
      .container{
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
.blur-active-element {
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    right: 0;
    top: -100px;
    width: 100%;
    z-index: -1;
}

.search__form {
  display: none;
  flex-direction: row;
  justify-content: flex-end;
  min-width: 300px;
  vertical-align: middle;

  @include range-bp(null, $screen-sm) {
    justify-content: center;
  }
}

.search__input {
  border: 1px solid #ccc;
  border-radius: 3px 0 0 3px;
  flex-basis: 100%;
  flex-shrink: 1;
  font-family: $font-family-sans-serif;
  font-weight: normal;
  padding: 7px 10px;
}

.search__submit {
  @include font-with-fallback(Gotham-Medium, 14px);

  background: #052264;
  border: none;
  border-radius: 0 3px 3px 0;
  color: #fff;
  cursor: pointer;
  flex-basis: 55px;
  flex-shrink: 0;
  padding: 0 5px;
  text-transform: uppercase;
  width: 100%;

  &:after {
    @include icon($fa-var-arrow-right, 18px);

    margin-left: 5px;
    vertical-align: middle;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.search__toggle {
  @include font-size(19px);
  background: none;
  border: none;
  color: #032c6b;
  cursor: pointer;
  display: inline-block;
}
.footer-secondary {
  background: #e3e3e3;
}

.footer-secondary__body {
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 15px 0;

  @include range-bp(null, 575px) {
    min-height: 104px;
    position: relative;
    z-index: 0;
  }
}

.footer-secondary__item {
  align-self: center;
  justify-self: center;
  transition: opacity 0.4s ease;

  @include range-bp($screen-sm-min, $screen-sm-max) {
    margin: 0 10px;
  }

  @include range-bp(576px, $screen-sm-min) {
    width: 150px;
  }

  @include range-bp(null, 575px) {
    left: 0;
    margin: 0;
    padding: 20px 0 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
    z-index: 1;

    &:not(.is-active){
      opacity: 0;
    }
  }
}

.footer-secondary__item-img {
    max-height: 68px;
    max-width: 180px;
}

.footer-secondary__item-img--bbb{
    max-width: 150px;

    @include range-bp(null, $screen-xs-max) {
      margin-top: 5px;
    }
}
.trust-bar {
  background: #fff;

  &__block {
    display: flex;
    justify-content: space-around;
    transform: translateX(0);
    transition: -webkit-transform 0.4s ease;
  
    @include range-bp($screen-xs, $screen-ss-max) {
      display: block;
      width: 855px;
    }
  
    @include bp-tn() {
      display: block;
      width: auto;
    }
  }

  &__body {
    box-shadow: 0 2px 13px rgba(71, 71, 71, 0.27);
    margin: 0;
    padding: 40px 0;

    @include range-bp(null, $screen-ss-max) {
      position: relative;
    }
  }

  &__item {
    width: 285px;
  
    @include bp-sm(){
      width: 250px;
    }
  
    @include range-bp($screen-xs, $screen-ss-max) {  
      float: left;
    }
  
    @include bp-tn(){
      margin: 0px auto 50px;
      text-align: center;
      width: 100%;

      &:last-child{
        margin-bottom: 0px;
      }
    }

    &-text {
      @include font-with-fallback(montserrat-regular, 18px);
    
      line-height: 36px;
      padding: 0px 10px;

      @include range-bp(null, $screen-tn-max) {
        margin: 0 auto;
        width: 290px;
      }
    
      @include range-bp(null, $screen-sm-max) {
        @include font-size(17px);
      }
    }

    &-title {
      @include font-with-fallback(montserrat-light, 38px);
    
      border-bottom: 1px solid #e7e7e7;
      font-weight: normal;
      line-height: 54px;
      margin: 0 0 10px;
      padding: 0px 10px;
    
      @include range-bp(null, $screen-sm-max) {
        @include font-size(32px);
      }
    }
  }

  &__slideshow {

    @include range-bp($screen-xs, $screen-ss-max) {
      margin: auto;
      overflow: hidden;
      width: 285px;
    }
  
    @include bp-tn() {
      margin: auto;
      overflow: visible;
      width: 100%;
    }

    &-controller {
      display: none;
    
      @include range-bp($screen-xs, $screen-ss-max) {
        @include font-size(60px);

        color: #dbdbdb;
        display: block;
        width: 100%;
      }
    
      &-prev {
        display: none;
        left: 100px;
        position: absolute;
        top: 26%;
    
        @include range-bp(null, $screen-xs-max) {
          left: 50px;
        }
    
        @include range-bp(null, $screen-tn-max) {
          left: 10px;
        }

        &>.fa{
          cursor: pointer;
          font-weight: bold;
        }
    
      }
    
      &-next {
        position: absolute;
        right: 100px;
        top: 26%;
    
        @include range-bp(null, $screen-xs-max) {
          right: 50px;
        }
    
        @include range-bp(null, $screen-tn-max) {
          right: 10px;
        }

        &>.fa{
          cursor: pointer;
          font-weight: bold;
        }
      }
    }
  }
}
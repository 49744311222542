.tab-content {
  background: #fff;
}

.tab-content__panel {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;

  &.is-collapsible {
    display: block;

    .tab-content__panel-item {
      border-bottom: 1px solid #959595;

      &--hw-atty {
        &.is-selected{
          border-top: none;
        }
      }

      &.is-selected{
        
        &::after {
          @include icon($fa-var-chevron-up,18px);
          @include poly-fluid-sizing('font-size',($screen-xs: 14px, $screen-ss: 16px, $screen-sm: 18px));

          color: #232222;
          top: 28px;

          @include range-bp($screen-tn, $screen-ss){
            top: 20px;
          }
        }
      }

      &::after {
        @include icon($fa-var-chevron-down);
        @include poly-fluid-sizing('font-size',($screen-xs: 14px, $screen-ss: 16px, $screen-sm: 18px));

        color: #959595;
        line-height: initial;
        position: absolute;
        right: 14px;
        top: 28px;

        @include range-bp($screen-tn, $screen-ss){
          top: 20px;
        }
      }

    }

  }

}

.tab-content__panel-item {
  @include font-with-fallback(montserrat-light, 38px);

  border-bottom: 2px solid transparent;
  cursor: pointer;
  flex: 0 1 auto;
  line-height: 45px;
  padding: 10px 14px;
  position: relative;
  top: 2px;

  @include range-bp(null, $screen-xs) {
    @include font-size(22px);
  }

  &:hover {
    border-color: #000;
    color: #232222;

    .tab-content__panel-item-link{
      color: #232222;
    }
  }

  &-wrap {
    margin: 0;
  }

  @at-root #{&}.is-selected{
    @include font-with-fallback(montserrat-light);
    
    border-color: #000;
    cursor: default;

    .tab-content__panel-item-link {
      color: #232222;
      cursor: default;
      &:focus {
        color: #232222;
        text-decoration: none;
      }
    }
  }
}

.tab-content__panel-item-link {
  @include font-with-fallback(montserrat-light, 38px);

  color: #959595;
  font-weight: normal;
  margin: 0;

  &:hover {
    color: #232222;
    text-decoration: none;
  }

  @include bp-xs(){
    @include font-size(30px);
  }

  @include bp-tn(){
    @include font-size(20px);
  }
}

.tab-content__tab {
  display: none;
  padding: 60px 30px 60px;

  &.is-active {
    display: block;
  }

  &-feature{
    padding-bottom: 0;
  }

  @include range-bp(null, $screen-sm-max) {
    & .feature-boxes{
      display: block;
    }
  }

  @include range-bp(null, $screen-ss) {
    padding: 45px 15px 45px;
    
    &-feature{
      padding-bottom: 0;
    }
  }
}

.tab-content__expander {
  @include font-with-fallback(montserrat-regular, 18px);

  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  color: #2167a6;
  margin-top: 30px;
  padding: 25px 0;
  text-align: center;

  @include bp-tn() {
    padding-left: 15px;
    padding-right: 15px;
  }

  &-btn {
    background-position-x: left;
    background-position-y: top;
    background-repeat: no-repeat;
    cursor: pointer;
    padding-left: 35px;

    &.link-issues {
      background-image: url('/bd/homepage-redesign/images/svg/all-issues.svg');

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url('/bd/homepage-redesign/images/png/all-issues.png');
        background-size: 20px;
      }
    }

    &.link-states {
      background-image: url('/bd/homepage-redesign/images/svg/all-states.svg');

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url('/bd/homepage-redesign/images/png/all-states.png');
        background-size: 30px;
      }
    }

    &.link-counties {
      background-image: url('/bd/homepage-redesign/images/svg/all-counties.svg');

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url('/bd/homepage-redesign/images/png/all-counties.png');
        background-size: 20px;
      }
    }

    &.link-cities {
      background-image: url('/bd/homepage-redesign/images/svg/all-cities.svg');

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url('/bd/homepage-redesign/images/png/all-cities.png');
        background-size: 20px;
      }
    }

    &.link-near-cities {
      background-image: url('/bd/homepage-redesign/images/svg/all-near-cities.svg');

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        background-image: url('/bd/homepage-redesign/images/png/all-near-cities.png');
        background-size: 14px 20px;
      }
    }

    @include range-bp($screen-sm, null) {
      &:nth-child(2) {
        margin-left: 20%;
      }
    }

    @include bp-ss() {
      &:nth-child(2) {
        margin-left: 10%;
      }
    }

    @include range-bp($screen-tn, $screen-xs+30) {
      @include font-size(16px);
      
      display: block;
      margin: 0 auto;
      padding-left: 45px;
      text-align: left;
      width: 200px;

      &:nth-child(2) {
        margin-left: auto;
        margin-top: 15px;
      }
    }

    @include range-bp(null, 375px) {
      @include font-size(16px);
    }

    &-show {
      &::before {
        content: 'Show More';
      }

      &.is-shown::before {
        content: 'Show Less';
      }
    }
  }

  &-show-other-category{
    max-width: 300px;
  
    @include range-bp($screen-md,null){
      display: block;
      margin: 0 auto !important;
      text-align: left;
    }
    
    @include range-bp(null, $screen-sm-max){
      display: none !important;
    }
  }
}